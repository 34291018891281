<template>
  <div class="dgsaUpload">
    <el-form
      style="margin-left: 65px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    >
      <el-form-item label="文件名称：" prop="file_name">
        <el-input
          placeholder="请输入文件名称"
          v-model="ruleForm.file_name"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="上传文件">
        <!-- <div>
          <div class="upload_box">
            <div class="upload">
              <fileUpload
                v-if="IS_LOGIN"
                :accept="accept"
                :autoUpload="true"
                :limit="5"
                @uploadCompleteOSS="uploadComplete"
                @getFileOriginalName="getFileName"
              >
                <img
                  class="image"
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
                />
              </fileUpload>
              <img
                v-else
                @click="goLogin"
                class="image"
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
              />
              <div class="type">支持PDF/Word/Excel/</div>
              <div class="type">PNG/JPG格式</div>
            </div>
            <div v-for="(item, index) in file" :key="index">
              <div class="item" v-if="item.type == 'file'">
                <i class="el-icon-error" @click="clearFile(index)"></i>
                <img :src="item.icon" alt="" />
                <div class="file_name">{{ item.name }}</div>
              </div>
              <div class="imgfile" v-if="item.type == 'img'">
                <i class="el-icon-error" @click="clearFile(index)"></i>
                <img :src="item.fileUrl" alt="" />
              </div>
            </div>
          </div>
        </div> -->
        <template v-if="IS_LOGIN">
          <fileUpload
            :accept="accept"
            :autoUpload="true"
            @uploadCompleteOSS="uploadComplete"
            @getFileOriginalName="getFileName"
          >
            <div class="upload" v-if="!file.length">
              <img
                class="image"
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
              />
              <div class="type">支持PDF/Word/Excel/</div>
              <div class="type">PNG/JPG格式</div>
            </div>
            <div v-else title="点击更换文件">
              <div v-for="(item, index) in file" :key="index">
                <div class="item" v-if="item.type == 'file'">
                  <i class="el-icon-error" @click.stop="clearFile(index)"></i>
                  <img :src="item.icon" alt="" />
                  <div class="file_name">{{ item.name }}</div>
                </div>
                <div class="imgfile" v-if="item.type == 'img'">
                  <i class="el-icon-error" @click.stop="clearFile(index)"></i>
                  <img :src="item.fileUrl" alt="" />
                </div>
              </div>
            </div>
          </fileUpload>
        </template>
        <template v-else>
          <div class="upload">
            <img
              @click="goLogin"
              class="image"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
            />
            <div class="type">支持PDF/Word/Excel/</div>
            <div class="type">PNG/JPG格式</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item>
        <div class="btn">
          <el-button class="close" @click="close">取消</el-button>
          <el-button
            :loading="loading"
            class="save"
            type="primary"
            @click="saveInfo"
            >保存</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import fileUpload from "@/baseComponents/fileUpload";
import { getFileType } from "~/baseUtils";
import { getenclosureImg } from "~/basePlugins/filters";
export default {
  props: {
    fileUrl: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      ruleForm: {
        file_name: "",
      },
      rules: {
        file_name: [
          { required: true, message: "请输入文件名称", trigger: "blur" },
        ],
      },
      accept: ".jpg,.png,.pdf,.doc,.docx,.xls,.xlsx",
      file: [],
      fileName: [],
      loading: false,
    };
  },
  components: {
    fileUpload,
  },
  watch: {
    fileUrl(nv) {
      if (nv) {
        this.ruleForm.file_name = nv.file_name;
        if (nv.file_url) {
          this.file = JSON.parse(nv.file_url);
        }
      }
    },
  },
  methods: {
    goLogin() {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
    },
    uploadComplete(result) {
      let suffix = getFileType(result.url);
      // console.log(suffix);
      let obj = {
        fileUrl: result.url,
        name: this.fileName[this.fileName.length - 1],
        icon: getenclosureImg(suffix),
      };
      let type = result.url.substring(result.url.length - 3, result.url.length)
      if (type.toLowerCase() == "jpg"||type.toLowerCase() == 'png') {
        obj.type = "img";
      } else {
        obj.type = "file";
      }
      // this.file.push(obj);
      this.file = [obj];
    },
    getFileName(name) {
      this.fileName.push(name);
      console.log(name);
      let startIndex = name.lastIndexOf(".");
      console.log(startIndex);
      if (startIndex !== -1) {
        let fileNames = name.substring(0, startIndex);
        if (!this.ruleForm.file_name && this.fileName.length === 1) {
          this.ruleForm.file_name = fileNames;
        }
      }
    },
    clearFile(index) {
      this.file.splice(index, 1);
    },
    close() {
      this.$router.go(-1);
      this.$refs.ruleForm.resetFields();
    },
    saveInfo() {
      this.$refs.ruleForm.validate((isOK) => {
        if (isOK) {
          if (!this.file.length) {
            return this.$message.error("请上传文件");
          }
          let file = JSON.stringify(this.file);
          this.$emit("submitInfo", this.ruleForm.file_name, file);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.dgsaUpload {
  margin-top: 22px;
  // height: 783px;
  padding-bottom: 30px;
  /deep/.el-input {
    width: 350px;
    height: 38px;
  }
  .upload_box {
    display: flex;
    flex-wrap: wrap;
  }
  .upload {
    width: 161px;
    height: 128px;
    background: #f7f8fa;
    border-radius: 2px;
    border: 1px dashed #e0e1e3;
    text-align: center;
    padding-top: 16px;
    margin-right: 15px;
    margin-bottom: 15px;
    .type {
      font-size: 12px;
      color: #97989a;
    }
    .image {
      cursor: pointer;
      margin-bottom: 17px;
    }
  }

  .item {
    width: 161px;
    height: 128px;
    background: #f7f8fa;
    border-radius: 2px;
    border: 1px dashed #e0e1e3;
    text-align: center;
    margin-right: 15px;
    margin-bottom: 15px;
    padding-top: 31px;
    position: relative;
    img {
      width: 32px;
      height: 32px;
      margin-bottom: 16px;
    }
    .file_name {
      font-size: 12px;
      color: #262728;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .imgfile {
    width: 161px;
    height: 128px;
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /deep/.el-icon-error {
    position: absolute;
    top: -10px;
    right: -11px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  /deep/.el-form-item__content {
    line-height: normal;
  }
  .btn {
    text-align: center;
    // margin: 130px 100px 135px 0;
    margin-right: 100px;
  }

  .save {
    width: 116px;
    height: 41px;
    border-radius: 7px;
  }

  .close {
    width: 116px;
    height: 41px;
    background: #ffffff;
    border-radius: 7px;
    border: 1px solid #e8e8e8;
    color: #606266;
  }
}
</style>
