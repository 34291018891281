<template>
  <div class="page">
    <div class="title">
      <div class="line"></div>
      <span class="txt">基本信息</span>
    </div>
    <div class="form" v-if="type == 1">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="产品编号：" prop="product_no">
          <el-input
            placeholder="请输入产品编号"
            v-model="ruleForm.product_no"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品名称：" prop="product_name">
          <el-input
            placeholder="请输入产品名称"
            v-model="ruleForm.product_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="文件名称：" prop="file_name">
          <el-input
            placeholder="请输入文件名称"
            maxlength="20"
            show-word-limit
            v-model="ruleForm.file_name"
          ></el-input>
          <div class="tip">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/warning.png"
              alt=""
            />
            <span class="tip_txt">文件名称限制20个字以内</span>
          </div>
        </el-form-item>
        <el-form-item label="上传文件：">
          <!-- <div>
            <div style="display: flex; flex-wrap: wrap">
              <div class="upload">
                <fileUpload
                  v-if="IS_LOGIN"
                  :accept="accept"
                  :autoUpload="true"
                  @uploadCompleteOSS="uploadComplete"
                  @getFileOriginalName="getFileName"
                >
                  <img
                    class="image"
                    src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
                  />
                </fileUpload>
                <img
                  v-else
                  @click="goLogin"
                  class="image"
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
                />
                <div class="type">支持PDF/Word/Excel/</div>
                <div class="type">PNG/JPG格式</div>
              </div>
              <div v-for="(item, index) in file" :key="index">
                <div class="item" v-if="item.type == 'file'">
                  <i class="el-icon-error" @click="clearFile(index)"></i>
                  <img :src="item.icon" alt="" />
                  <div class="file_name">{{ item.name }}</div>
                </div>
                <div class="imgfile" v-if="item.type == 'img'">
                  <i class="el-icon-error" @click="clearFile(index)"></i>
                  <img :src="item.fileUrl" alt="" />
                </div>
              </div>
            </div>
          </div> -->
          <template v-if="IS_LOGIN">
            <fileUpload
              :accept="accept"
              :autoUpload="true"
              @uploadCompleteOSS="uploadComplete"
              @getFileOriginalName="getFileName"
            >
              <div class="upload" v-if="!file.length">
                <img
                  class="image"
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
                />
                <div class="type">支持PDF/Word/Excel/</div>
                <div class="type">PNG/JPG格式</div>
              </div>
              <div v-else title="点击更换文件">
                <div v-for="(item, index) in file" :key="index">
                  <div class="item" v-if="item.type == 'file'">
                    <i class="el-icon-error" @click.stop="clearFile(index)"></i>
                    <img :src="item.icon" alt="" />
                    <div class="file_name">{{ item.name }}</div>
                  </div>
                  <div class="imgfile" v-if="item.type == 'img'">
                    <i class="el-icon-error" @click.stop="clearFile(index)"></i>
                    <img :src="item.fileUrl" alt="" />
                  </div>
                </div>
              </div>
            </fileUpload>
          </template>
          <template v-else>
            <div class="upload">
              <img
                @click="goLogin"
                class="image"
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/addFile.png"
              />
              <div class="type">支持PDF/Word/Excel/</div>
              <div class="type">PNG/JPG格式</div>
            </div>
          </template>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button class="close" @click="close">取消</el-button>
            <el-button
              :loading="loading"
              class="save"
              type="primary"
              @click="saveInfo"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
    <dgsaFileUpload
      ref="fileUpload"
      @submitInfo="submitInfo"
      :fileUrl="fileDetail"
      v-else
    ></dgsaFileUpload>
  </div>
</template>

<script>
import fileUpload from "@/baseComponents/fileUpload";
import { getFileType } from "~/baseUtils";
import { getenclosureImg } from "~/basePlugins/filters";
import dgsaFileUpload from "./dgsaFileUpload";
export default {
  props: {},
  data() {
    return {
      ruleForm: {
        product_no: "",
        product_name: "",
        file_name: "",
      },
      rules: {
        product_no: [
          { required: true, message: "请输入产品编号", trigger: "blur" },
        ],
        product_name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        file_name: [
          { required: true, message: "请输入文件名称", trigger: "blur" },
          { max: 20, message: "文件名称不能超过20个字", trigger: "blur" },
        ],
      },
      accept: ".jpg,.png,.pdf,.doc,.docx,.xls,.xlsx",
      file: [],
      fileName: [],
      loading: false,
      type: 0,
      fileDetail: {},
    };
  },
  watch: {},
  components: {
    fileUpload,
    dgsaFileUpload,
  },
  async created() {
    this.type = this.$route.query.type;
    if (this.$route.query.edit == "true") {
      let res = await this.$store.dispatch("API_certificate/fileQuery", {
        dga_files_id: this.$route.query.id,
      });
      if (res.success) {
        this.fileDetail = res.data;
        this.ruleForm.product_no = this.fileDetail.product_no;
        this.ruleForm.product_name = this.fileDetail.product_name;
        this.ruleForm.file_name = this.fileDetail.file_name;
        if (this.fileDetail.file_url) {
          this.file = JSON.parse(this.fileDetail.file_url);
        }
      }
    }
  },
  mounted() {},
  methods: {
    goLogin() {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
    },
    uploadComplete(result) {
      let suffix = getFileType(result.url);
      let obj = {
        fileUrl: result.url,
        name: this.fileName[this.fileName.length - 1],
        icon: getenclosureImg(suffix),
      };
      let type = result.url.substring(result.url.length - 3, result.url.length)
      if (type.toLowerCase() == "jpg"||type.toLowerCase() == 'png') {
        obj.type = "img";
      } else {
        obj.type = "file";
      }
      // this.file.push(obj);
      this.file = [obj];
    },
    getFileName(name) {
      this.fileName.push(name);
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        let fileNames = name.substring(0, startIndex);
        if (!this.ruleForm.file_name && this.fileName.length === 1) {
          this.ruleForm.file_name = fileNames;
        }
      }
    },
    clearFile(index) {
      this.file.splice(index, 1);
      this.fileName.splice(index, 1);
    },
    close() {
      this.$router.go(-1);
      this.$refs.ruleForm.resetFields();
    },
    saveInfo() {
      this.$refs.ruleForm.validate(async (isOK) => {
        if (isOK) {
          if (!this.file.length) {
            return this.$message.error("请上传文件");
          }
          this.loading = true;
          if (this.$route.query.edit == "true") {
            let params = {
              dga_file_id: this.$route.query.id,
              ...this.ruleForm,
              file_url: JSON.stringify(this.file),
              user_id: this.USER_INFO.id
            };
            let res = await this.$store.dispatch(
              "API_certificate/uploadFileEdit",
              params
            );
            if (res.success) {
              this.loading = false;
              this.$router.go(-1);
              this.$message.success("文件编辑成功");
            } else {
              if (res.errorcode === 1000) {
                this.loading = false;
                this.$message.warning("产品编号重复");
              } else if (res.errorcode === 1041) {
                this.loading = false;
                this.$message.warning("产品不存在");
              }
            }
          } else {
            let params = {
              ftype: 1,
              ...this.ruleForm,
              creater: this.USER_ID,
              file_url: JSON.stringify(this.file),
            };
            let res = await this.$store.dispatch(
              "API_certificate/uploadFile",
              params
            );
            if (res.success) {
              this.loading = false;
              this.$router.go(-1);
              this.$message.success("文件上传成功");
            } else {
              if (res.errorcode === 1000) {
                this.loading = false;
                this.$message.warning("产品编号重复");
              }
            }
          }
        }
      });
    },
    async submitInfo(file_name, file) {
      this.$refs.fileUpload.loading = true;
      if (this.$route.query.edit == "true") {
        let params = {
          dga_file_id: this.$route.query.id,
          file_name: file_name,
          file_url: file,
          user_id: this.USER_INFO.id
        };
        let res = await this.$store.dispatch(
          "API_certificate/uploadFileEdit",
          params
        );
        if (res.success) {
          this.$refs.fileUpload.loading = false;
          this.$message.success("文件编辑成功");
          this.$router.go(-1);
        } else {
        }
      } else {
        let params = {
          ftype: this.type,
          file_name: file_name,
          file_url: file,
          creater: this.USER_ID,
        };
        let res = await this.$store.dispatch(
          "API_certificate/uploadFile",
          params
        );
        if (res.success) {
          this.$refs.fileUpload.loading = false;
          this.$message.success("文件上传成功");
          this.$router.go(-1);
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 1200px;
  // height: 783px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 22px;
  overflow: hidden;

  .title {
    width: 1136px;
    height: 48px;
    background: #fafafa;
    margin: 0 auto;
    margin-top: 26px;
    display: flex;
    align-items: center;
    .line {
      width: 3px;
      height: 48px;
      background: #116bff;
    }
    .txt {
      font-size: 16px;
      font-weight: 600;
      color: #27292d;
      margin-left: 20px;
    }
  }

  .form {
    margin-left: 65px;
    margin-top: 26px;
    /deep/.el-input {
      width: 350px;
      height: 38px;
    }

    .tip {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #e34d59;
      margin-top: 13px;
      .tip_txt {
        margin-left: 3px;
      }
    }

    /deep/.el-form-item__content {
      line-height: normal;
    }

    .upload {
      width: 161px;
      height: 128px;
      background: #f7f8fa;
      border-radius: 2px;
      border: 1px dashed #e0e1e3;
      text-align: center;
      padding-top: 16px;
      margin-right: 15px;
      margin-bottom: 15px;
      .type {
        font-size: 12px;
        color: #97989a;
      }
      // .type1 {
      //   margin-top: 17px;
      // }
      .image {
        cursor: pointer;
        margin-bottom: 17px;
      }
    }

    .item {
      width: 161px;
      height: 128px;
      background: #f7f8fa;
      border-radius: 2px;
      border: 1px dashed #e0e1e3;
      text-align: center;
      margin-right: 15px;
      margin-bottom: 15px;
      padding-top: 31px;
      padding: 31px 6px 0 6px;
      position: relative;
      img {
        width: 32px;
        height: 32px;
        margin-bottom: 16px;
      }
      .file_name {
        font-size: 12px;
        color: #262728;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .imgfile {
      width: 161px;
      height: 128px;
      position: relative;
      margin-right: 15px;
      margin-bottom: 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    /deep/.el-icon-error {
      position: absolute;
      top: -10px;
      right: -11px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .btn {
      text-align: center;
      // margin: 130px 100px 135px 0;
      margin-bottom: 80px;
    }

    .save {
      width: 116px;
      height: 41px;
      border-radius: 7px;
    }

    .close {
      width: 116px;
      height: 41px;
      background: #ffffff;
      border-radius: 7px;
      border: 1px solid #e8e8e8;
      color: #606266;
    }
  }
}
</style>
